import { action } from "mobx";

import { appConfigurationStore } from "common/stores/app-configuration-store";

import { IDefaultsData } from "common/types/defaults-data";
import { IGroupDetails } from "common/types/users-groups";
import { IRole } from "common/types/roles";
import {
  AppTheme,
  //DefaultTheme,
  // ThemedStyledComponentsModule,
} from "styled-components";
import { blackMamba } from "styles/theme";
import { getThemePalette } from "styles/themes/blog-themes";

export const setDefaultAvatars = action((avatars: IDefaultsData) => {
  appConfigurationStore.defaultUserAvatars = avatars.user_avatars;
  appConfigurationStore.defaultTeamAvatars = avatars.team_icons;
});

export const setUsersGroups = action((usersGroups: IGroupDetails[]) => {
  appConfigurationStore.usersGroups = usersGroups;
});

export const setRoles = action((values: IRole[]) => {
  appConfigurationStore.roles = values;
});

export const setAppTheme = action((themeName: string) => {
  appConfigurationStore.appTheme = getThemePalette(themeName);
  localStorage.setItem("settings", JSON.stringify({ theme: themeName }));
});
