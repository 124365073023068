import { createGlobalStyle } from "styled-components";

// ICONS AND IMAGES

import logo_mask from "../assets/images/logo-mask.svg";

export const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: "Agency FB Bold";
        font-style: normal;
        font-weight: 700;
        src: local("Agency FB Bold"), local("AgencyFB-Bold"),
          url(https://allfont.net/cache/fonts/agency-fb-bold_cee84847c4ab16cf2b0952d063712724.woff)
            format("woff"),
          url(https://allfont.net/cache/fonts/agency-fb-bold_cee84847c4ab16cf2b0952d063712724.ttf)
            format("truetype");
      }
 

     html {
      -webkit-text-size-adjust: 100%;
      -webkit-tap-highlight-color: rgba(0, 0, 21, 0);
      font-family: sans-serif;
      line-height: 1.15;
    }

    img, svg {
    vertical-align: middle;
   }

   input,  button, select, optgroup, textarea {
    margin: 0; // Remove the margin in Firefox and Safari
    font-family: inherit; 
    line-height: inherit;
      font-size: inherit;
   }

   

  input:-webkit-autofill {
  border: 1px solid #your-color !important;
  box-shadow: 0 0 0px 1000px transparent inset; 
  -webkit-text-fill-color: #fff !important; 
  transition: background-color 5000s ease-in-out 0s;
}

input[type="time"]::-webkit-calendar-picker-indicator{
  filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
}


     body {
     background-color: #ebedef;
     color: #3c4b64;
     font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
     font-size: .875rem;
     font-weight: 400;
     line-height: 1.5;
     margin: 0;
     overflow-x: hidden;
     text-align: left;
    }

    a {
    color: rgb(153, 153, 153);
    text-decoration: none;
}
    a:hover {
    color: rgb(255, 255, 255);
    text-decoration: none;
}

    button{
     cursor: pointer;
    }

    :root { 

    *, :after, :before {
     box-sizing: border-box;
    }

    .rich-text-editor-link{
      font-family: RobotoSerif;
      font-size: 18px;
      font-weight: normal;
      letter-spacing: normal;
      color: #2a84ff;
      text-decoration: none;
      border-bottom: solid 1px;
    }

    .rich-text-editor-link:hover{
      color: #2a84ff;
      text-decoration: none;
      border-bottom: solid 1px;
    }

   

        .c-wrapper {
            display: flex;
            flex: 1 1 0%;
            flex-direction: column;
            min-width: 0px;
            min-height: 100vh;
            max-width: 100vw;
            height: 98vh;
            overflow: hidden;
        }
 
     
        div {
            font-family: 'Roboto', sans-serif;
            font-weight: normal;
            letter-spacing: 0.25px;
        }

        ::-webkit-scrollbar {
            -webkit-appearance: none;
        }
        
        ::-webkit-scrollbar:vertical {
            width: 4px;
        }
        
        ::-webkit-scrollbar:horizontal {
            height: 0px;
        }
        
        ::-webkit-scrollbar-thumb {
            border-radius: 8px; 
            background-color: rgba(white, 0.2);  
        }

    }
 
    ::-webkit-scrollbar {
        -webkit-appearance: none;
        
    }
    
    ::-webkit-scrollbar:vertical {
        width: 4px;
    }
    
    ::-webkit-scrollbar:horizontal {
        height: 0px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 8px; 
        background-color: rgba(white, 0.2); 
    }
 
    .c-app {
        display: flex;
        flex-direction: row;
        min-height: 100vh;
        /* 
        GLOBALS 
        */

       
        color: #fff;  
        background-color: #131313;
        

        .c-body{
            background: #131313; 
            color: ${({ theme }) => theme.text};
        }

        a {
            color: ${({ theme }) => theme.link};
            text-decoration: none;
        }
        a:hover {
            color: ${({ theme }) => theme.link_hover};
            text-decoration: none;
        }

        div {
            font-family: 'Roboto', sans-serif;
            font-weight: normal;
            letter-spacing: 0.25px;
        }

        text:focus {
            outline: unset !important;
        }

        :focus {
            outline: unset !important;
        }

   
        //avatar
        .user-avatar {
            margin-top: 0px;
            width: 48px;
            height: 48px;
        }
     
    
        // buttons grid
        .buttons { 
            margin-top: 8px;
            margin-bottom: 10px; 
        }
        
        .btn:focus, .btn.focus {
            outline: 0;
            box-shadow: unset;
        }
     
        /* LOADER */
        .loader{
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-right: -50%;
            transform: translate(-50%, -50%);
            background-color: transparent;
        }
    


        /* HEADER */ 
        .menus{
            display:flex;
            align-items:center;
            justify-content:flex-end;
            height: 100%;

        }
    
        .c-header{
            background-color: #131313;
            color: ${({ theme }) => theme.header_text};
            border-bottom: 2px solid ${({ theme }) =>
              theme.header_border || "rgb(38, 38, 38)"};
            height: 52px;
            min-height: 52px;
            align-content: center;
            justify-content: space-between;
            flex-wrap: nowrap;
            display: flex;
            flex-direction: row;
            flex-shrink: 0;
            
           
           
            .clock{
                position:relative;
                font-family: 'Roboto', sans-serif;
                font-size: 0.8rem;
                text-align: right;
                color: rgba(255, 255, 255, 0.5);
                padding: 0 14px 0 14px;
            }
            .header-buttons {
                position:relative;
                display:flex;
                align-items:center;
                justify-content:flex-end;
                height: 100%;
            }
           
            .mode-blog-campaign-studio{
                position:relative;
                display:flex;
                align-items:center;
                height: 100%;
                left:300px;
            }
             
             
        }
       
        .bg-light {
            background-color: ${({ theme }) =>
              theme.header_dropdown_header} !important;
        }
        .dropdown-item {
            color: ${({ theme }) => theme.header_dropdown_item_text}
        }

        .dropdown-item:hover {
            background-color: ${({ theme }) => theme.header_dropdown_item}
        }

        .dropdown-header:focus {
            outline: none
        }        
 

        // header-info-active-campaign-Idle
        .c-app .c-wrapper .header-info-active-campaign-Idle {
            border-left:7px solid #ffffff;
        }

        // header-info-active-campaign-Active
        .c-app .c-wrapper .header-info-active-campaign-Active {
            border-left:7px solid rgba(#49bd95, 0.7);
        }


        // header-info-active-campaign-Setup
        .c-app .c-wrapper .header-info-active-campaign-Setup {
            border-left: 7px solid #3399ff;
        }
       
         
        /* FOOTER */
        .c-footer {
            background-color: ${({ theme }) => theme.footer};
            border-top: 0px solid ${({ theme }) => theme.footer_border};
        }
 
         
        /* FORMS */
 
        .select-input {
            .css-1pahdxg-control {
                color: ${({ theme }) => theme.select_text};
                background-color: ${({ theme }) => theme.select};
                border-color: ${({ theme }) => theme.select_border};
                outline: 0;
                box-shadow: 0 0 0 0.2rem ${({ theme }) => theme.select_border};
            }
            .css-1pahdxg-control:hover {
                border-color: ${({ theme }) => theme.select_border};
            } 
        }

        
        input[type="date"]::-webkit-calendar-picker-indicator {
            opacity: 1;
            display: block;
            filter:  brightness(0) saturate(100%) invert(82%) sepia(22%) saturate(12%) hue-rotate(48deg) brightness(103%) contrast(86%);
            width: 16px;
            height: 16px;
            border-width: thin;
            outline: unset;
        }

        input[type="time"]::-webkit-calendar-picker-indicator {
            opacity: 1;
            display: block;
            filter:  brightness(0) saturate(100%) invert(82%) sepia(22%) saturate(12%) hue-rotate(48deg) brightness(103%) contrast(86%);
            width: 16px;
            height: 16px;
            border-width: thin;
            outline: unset;
        }
 
        // custom shape
        .switches {
            margin-top: 10px;
            text-align: left;
        }
    
        .switch-title{
            margin-left: 8px;
            text-align: left;
        }
        .switch{
            padding-top: 5px;
        }

        .c-switch-input:focus ~ .c-switch-slider {
            color: #768192;
            background-color: #fff;
            border-color: #000000;
            outline: 0;
            box-shadow: unset;
        }



        /* RADIO */
        /* COMMON RADIO AND CHECKBOX STYLES  */
        
        .form-check-input {
            margin-top: 4px;
        }

        /*
        input[type=radio],
        input[type=checkbox]{ 
            visibility: hidden;
            position: absolute;
        }
        input[type=radio] + label:before,
        input[type=checkbox] + label:before{
            height:12px;
            width:12px;
            margin-right: 2px;
            content: " ";
            display:inline-block;
            vertical-align: baseline;
            border: 1px solid ${({ theme }) => theme.form_border};
        }
        input[type=radio]:checked + label:before,
        input[type=checkbox]:checked + label:before{
            background:${({ theme }) => theme.radio};
        }

        /* CUSTOM RADIO AND CHECKBOX STYLES */
        input[type=radio] + label:before{
            border-radius:50%;
        }
        input[type=checkbox] + label:before{
            border-radius:2px;
        }
        */

 
        /* CARD */
    
        .card {
            background-color: #131313;
            border: unset;
            margin-bottom: 0px !important;
        }
        
 
        /* ALERTS */
        .alert-danger {
            color: ${({ theme }) => theme.alert_danger_text};
            background-color: ${({ theme }) => theme.alert_danger};
            border-color: ${({ theme }) => theme.alert_danger_border};
        }

        .alert-info {
            color: ${({ theme }) => theme.alert_info_text};
            background-color: ${({ theme }) => theme.alert_info};
            border-color: ${({ theme }) => theme.alert_info_border};
        }
        .alert-success {
            color: ${({ theme }) => theme.alert_success_text};
            background-color: ${({ theme }) => theme.alert_success};
            border-color: ${({ theme }) => theme.alert_success_border};
        }
     

        
        // CHARTS

        .chart {
            padding-bottom: 30px;
        }
       
        .chart-title {
            font-size: 18px;
            text-align: left;
            padding-bottom: 10px;
        }
        .export-button {
            float: right;
        }
 
    
        // NOTIFICATIONS
        .clear-notifications{
            padding-left: 10px;
            cursor: pointer;
        }
        .clear-notifications:hover{
            text-decoration-line: underline;
        }
     
        .c-header .c-header-nav .c-header-nav-link:hover, .c-header .c-header-nav .c-header-nav-link:focus, .c-header .c-header-nav .c-header-nav-btn:hover, .c-header .c-header-nav .c-header-nav-btn:focus {
            color: unset;
        }



        /* MODALS */
    
        .modal-dialog {
            position: relative;
            top: 40%;
            transform: translateY(-40%) !important;
            width: 100%;
            text-align: left;
        }

        .modal-content {
            border: unset;
            background-color: ${({ theme }) => theme.modal_background}
        }
    
       
    
        .modal-body {
            position: relative;
            flex: 1 1 auto;
            padding: 1rem;
            margin-top: 16px;
        }
    
        .modal-footer {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-end;
            padding: 0.75rem;
            border-top: 1px solid;
            border-bottom-right-radius: calc(0.3rem - 1px);
            border-bottom-left-radius: calc(0.3rem - 1px);
            border-color: ${({ theme }) => theme.modal_footer_border};
            padding-left: 0px;
            padding-right: 0px;
            margin-left: 10px;
            margin-right: 10px;
        }
        
        

        .modal-button {
          
            width: 80px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            font-size: 11px;
            vertical-align: middle;
        }
    
        .modal-button:active{
            background-color: ${({ theme }) => theme.button};
            outline: unset;
        }
        .modal-button:hover{
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .modal-button:not(:disabled):not(.disabled):active:focus, .show > .modal-button.dropdown-toggle:focus {
            box-shadow: unset;
        }
    
        .modal-button:disabled {
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            opacity: 0.20;
        }
        .modal-button:focus{
            outline: unset;
        }

 
     
        .modal-delete-button{
            width: 80px;
            height: 28px;
            background-color: ${({ theme }) => theme.btn_danger};
            border:  1px solid ${({ theme }) => theme.btn_danger_border};
            color: ${({ theme }) => theme.btn_danger_text};
            border-radius: 0.6em;
            font-weight: 500;
            font-size: 11px;
            vertical-align: middle;
        }
    
        .modal-delete-button:active{
            background-color: ${({ theme }) => theme.btn_danger}; 
            outline: unset;
        }
        .modal-delete-button:hover{
            border-color: ${({ theme }) => theme.btn_danger_hover};
            color: ${({ theme }) => theme.btn_danger_hover};
        }
        .modal-delete-button:not(:disabled):not(.disabled):active:focus, .show > .modal-delete-button.dropdown-toggle:focus {
            box-shadow: unset;
        }    
    
        .modal-delete-button:disabled {
            border:  1px solid ${({ theme }) => theme.btn_danger_border};
            color: ${({ theme }) => theme.btn_danger_text};
            opacity: 0.20;
        }
        .modal-delete-button:focus{
            outline: unset;
        }
 
        .margin-top-10{
            margin-top: 10px;
        }
        .margin-top-16{
            margin-top: 16px;
        }


        /*
        .modal-button {
            margin-right: 10px;
            width: 80px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            font-size: 11px;
            vertical-align: middle;
        }
    
        .modal-button:active{
            background-color: ${({ theme }) => theme.button};
            outline: unset;
        }
        .modal-button:hover{
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .modal-button:not(:disabled):not(.disabled):active:focus, .show > .modal-button.dropdown-toggle:focus {
            box-shadow: unset;
        }
    
        .modal-button:disabled {
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            opacity: 0.20;
        }
        .modal-button:focus{
            outline: unset;
        }

        */




        .modal-cancel-button{ 
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            font-size: 11px;
            padding-top: 6px;
        }
    
        .modal-cancel-button:active{
            background-color: ${({ theme }) => theme.button};    
        }
        .modal-cancel-button:hover{
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .modal-cancel-button:not(:disabled):not(.disabled):active:focus, .show > .modal-cancel-button.dropdown-toggle:focus {
            box-shadow: unset;
        }   



        .modal-form-buttons {
            float: right;
            padding-right: 0px;
            margin-right: 0px;
            margin-top: 10px;
        }

        .close:focus{
            outline: unset;
        }
        .modal-error-message {
            margin: 0 auto;
            color: red;
        }

 
        
        /* LOGIN */
        .login-page-wrapper .container{
            position: absolute;
            top: 300px;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            min-height: 50%;
            min-width: 50%;
        }
        .bg-primary {
            background-color: #152433 !important;
        }
        .card-group{
            opacity: 0.8;
        }
    
        .login-page-wrapper {
            width: 100px;
        }
        

        .login-logo { 
            height: 100%;
            max-width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .login-logo-image {
            max-height: 240px;
            max-width: 250px;

            vertical-align: middle;
            display: inline-block;
        }


        .login-page-wrapper video {
            position: fixed;
            object-fit: fill;
            top: 0; right: 0; bottom: 0; left: 0;
            width: 100%;
            height: 100%;
            opacity: 0.9;
        }



        .login-title {
            color: ${({ theme }) => theme.text};
        }
 
       
        .progress { 
            margin-bottom: unset;
            margin-top: unset;
        }
    
        .start-time {
            vertical-align: middle;
        }    
        .campaign {
            vertical-align: middle;
        }
        .duration {
            vertical-align: middle;
        }
        .place {
            vertical-align: middle;
            text-align: center;
        }
        .points {
            vertical-align: middle;
            text-align: center;
        }
    
        /* EVENTSNEW */
    
        .events-campaign-statistics-label {
            font-family: 'Roboto', sans-serif;
            font-size: 14px; 
            color: rgba(255, 255, 255, 0.5);
            line-height: normal;
        }
        .events-campaign-statistics-data {
            font-family: 'Roboto', sans-serif;
            font-size: 24px;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.8);
            word-break: break-all;
        }
        .medium-font{
            font-size: 14px;
        }
        .small-font{
            font-size: 12px;
        }
        .events-list-title {
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            font-weight: 550;
            color: rgba(255, 255, 255, 0.8);
        }
       
        .place_button {
            width: 48;       
        }
        .activity_button {
            width: 58;
        }
        .sortby_active {
            background-color: rgba(23, 191, 131, 0.7);
            color: #fff;
            outline: none;
        }
        .general_event_card {
            height: auto; 
            width: auto;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px; 
            border-right: 2px solid #393939;
            outline-style: unset;
            outline-width: unset;
            outline-color: none;
            background-color: #2a2a2a;    
        }
        .target {
            border-radius: 0px;       
        }
        .target-empty {
            padding: 0px;
        }
       
        .users {
            min-width: 96px;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            border-right: none;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        .col_margins {
            margin: 16px 0px 16px 16px; 
        }
        
        .card_text_layout {
            margin: 0px 0px 0px 0px; 
            word-break: "break-all";
            line-height: normal;
        }
        .card_big_title {
            font-family: 'Roboto',sans-serif;
            font-size: 24px;
            font-weight: 550;
            color: #fff;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: auto;
            max-width: 150px
        }
        .team_place_num {
            font-family: 'Roboto', sans-serif;
            font-size: 46px;
            font-weight: bold;
            color: #49bd95;
        }
        .place-title {
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: normal;
            color: rgba(255, 255, 255, 0.8);
        }
        .target-title {
            font-family: 'Roboto', sans-serif;
            font-size: 18px;
            font-weight: normal;
            color: rgba(255, 255, 255, 0.8);
        }
      
       
       
       
        .events-team-btn {
            background-color: rgba(0,0,0,0);
            border: none;  
            outline: none;
        }
        
        .events-team-btn:active {
            opacity: 0.5;
            border: none;  
            outline: none;
        }
        .events-redeploy-btn {
            background-color: rgba(0,0,0,0);
            outline: none;
            width: 26px;
            height: 28px;
            border: solid 2px rgba(255, 255, 255, 0.5);
            border-radius: 8px;
            margin-left: 8px;
            align-items: center;
            justify-content: center;
            display: flex;
        }
        .events-redeploy-btn:disabled {
            
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            opacity: 0.20;
            
        }
    
        
        .target-row-grid {
            width: 100%;
            display: grid; 
            grid-template-columns: repeat(auto-fit,minmax(290px,1fr));
            margin-bottom: -2px;
        }
        .target-row-grid-empty {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
       
        .player-content {
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            font-weight: normal;
            color: rgba(255, 255, 255, 0.8);
        }
        .player-content-green {
            color: rgba(23, 191, 131, 0.7);
        }
        
      
        .log-display {
            width: 90%;
        }
         
        .events-empty-stats {
            color: rgba(255, 255, 255, 0.25);
        }
        .events-empty-2 {
            color: rgba(255, 255, 255, 0.5);
        }
       
        .events-avatar {
            height: 30px; 
            width: 30px;
            border-radius: 50%;
        }
       
       
     
        /* CAMPAIGNS */

 

        .css-26l3qy-menu, .css-1w9j89e-menu {
            background-color: ${({ theme }) => theme.select};            
 
            ::-webkit-scrollbar {
                -webkit-appearance: none;
                
            }
            
            ::-webkit-scrollbar:vertical {
                width: 4px;
            }
            
            ::-webkit-scrollbar:horizontal {
                height: 0px;
            }
            
            ::-webkit-scrollbar-thumb {
                border-radius: 8px; 
                background-color: rgba(white, 0.2);
                
            }

        }

        .css-4ljt47-MenuList, .css-wzlap7-MenuList { 
            ::-webkit-scrollbar {
                -webkit-appearance: none;
                
            }
            
            ::-webkit-scrollbar:vertical {
                width: 4px;
            }
            
            ::-webkit-scrollbar:horizontal {
                height: 0px;
            }
            
            ::-webkit-scrollbar-thumb {
                border-radius: 8px; 
                background-color: rgba(white, 0.2);
                
            }
        }

   
      
    
        .example-thumb {
            font-size: 0.9em;
            text-align: center;
            color: rgba(0, 0, 0, 0.5);
            cursor: pointer;
            box-shadow: 0 2px 5px rgba(0, 0, 21, 0.3);
            background-color: ${({ theme }) => theme.switch};
        }
    
       
        .example-thumb:focus {
            outline: unset;
            box-shadow: 0px 0px 0px 7px rgba(59, 163, 248, 0.22);
            -moz-box-shadow: 0px 0px 0px 7px rgba(59, 163, 248, 0.22);
            box-shadow: 0px 0px 0px 7px rgba(59, 163, 248, 0.22);
        }
    
        .example-thumb:hover { 
            box-shadow: 0px 0px 0px 7px rgba(59, 163, 248, 0.22);
            -moz-box-shadow: 0px 0px 0px 7px rgba(59, 163, 248, 0.22);
            box-shadow: 0px 0px 0px 7px rgba(59, 163, 248, 0.22);
        }
    
        .example-track {
            position: relative;
            background: #F5F5F5;
            border-top-left-radius: 50em;
            border-bottom-left-radius: 50em;
            box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);
            -moz-box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);
            box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);   
            margin-left: 10px; 
            margin-right: 10px;  
        }
    
        .example-track.example-track-1 {
            background: ${({ theme }) => theme.slider_range};
            box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);
            -moz-box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);
            box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);
        }
    
        .example-track.example-track-2 {
            background: #F5F5F5;
            border-top-right-radius: 50em;
            border-bottom-right-radius: 50em;
            box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);
            -moz-box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);
            box-shadow: inset 1px 6px 10px -8px rgba(0,0,0,0.49);
        }
    
        .horizontal-slider .example-track {
            top: 16px;
            height: 5px;
        }
    
        .horizontal-slider .example-thumb {
            top: 5px;
            width: 25px;
            height: 25px;
            line-height: 28px;
            border-radius: 50em;
        }
    
        .vertical-slider .example-thumb {
            left: 1px;
            width: 48px;
            line-height: 40px;
            height: 50px;
        }
    
        .vertical-slider .example-track {
            left: 20px;
            width: 10px;
            border: unset;
        }        
  
       


    
        /* target tab */
        .target-wrapper {
            margin: 10px;
            padding-top: 16px;
            margin-left: 0px;
            overflow-y: unset;
            overflow-x: hidden;
            height: calc(75vh - 91px);
        }
        .target-title {
            color: ${({ theme }) => theme.campaign_title};
            font-size: 18px;
        }
        .target-summary {
            padding-top: 24px;
        }
        .target-image {
            width: 100%;
            height: 200px;
            box-shadow: 0 -0.5px 4px 0 rgba(${({ theme }) =>
              theme.campaign_target_image_shadow}, 0.35), 0 1px 4px 0 rgba(${({
  theme,
}) => theme.campaign_target_image_shadow}, 0.35);
            border-radius: 0.4em;
        }
        .target-hline {
            margin-top: 12px;
            margin-bottom: 12px;
            border-bottom: 1px solid transparent;
            border-image: 
            linear-gradient(
              to right, 
              rgba(255,255,255, 0),
              rgba(0,0,0, 0.2),
              rgba(255,255,255, 0) 
            ) 1;
        }
    
        /* mission */
        .mission-wrapper {
            margin: 10px;
            padding-top: 16px;   
            margin-left: 0px;
            //overflow-y: scroll;
            overflow-y: unset;
        }
        .mission-title {
            color: ${({ theme }) => theme.campaign_title};
            font-size: 16px;
            padding-bottom: 10px;
        }
        .mission-subtitle {
            color: ${({ theme }) => theme.campaign_subtitle};
            font-size: 13px;
            padding-right: 5px;
        }
        .mission-text {
            padding-bottom: 20px;
        }
        .mission-image {
            width: 100%;
        }
        .mission-hline {
            border-bottom: 1px solid ${({ theme }) =>
              theme.campaign_mission_hline};
            margin-top: 12px;
            margin-bottom: 12px;
        }
    
        .right-border{
            border-right: 1px solid ${({ theme }) =>
              theme.campaign_mission_border};
            border-image: 
            linear-gradient(
              to bottom, 
              rgba(255,255,255, 0) ,
              rgba(0,0,0, 0.2),
              rgba(255,255,255, 0) 
            ) 1 100%;
        }
    
        .mission-right-pane{
            padding-top: 50px;
        }
    
        /* brief summary tab */
        .brief-wrapper {
            margin: 10px;
            padding-top: 16px;
            margin-left: 0px;
            overflow-x: auto;
            height: calc(75vh - 91px);
        }
        .brief-title {
            color: ${({ theme }) => theme.campaign_title};
            font-size: 18px;
            text-align: center;
        }
        .brief-summary {
            padding-top: 24px;
            width: 60%;
            text-align: leftPlease enter target name;
            margin: 0 auto;
        }
        .video-wrapper {
            margin: 0 auto;
            text-align: center;
            padding-top: 32px;
        }
        .brief-video {
            width: 50%;
            position: relative; 
            box-shadow: 0 -0.5px 4px 0 rgba(${({ theme }) =>
              theme.campaigns_video_brief_video_shadow}, 0.35), 0 1px 4px 0 rgba(${({
  theme,
}) => theme.campaigns_video_brief_video_shadow}, 0.35);
            border-radius: 0.4em;
        }

        /* campaign card */
        .campaign-grid {
            padding-top: 12px;
            padding-left: 32px;
            padding-bottom: 12px;
            text-align: center;
            color: ${({ theme }) => theme.text};
            background-color: ${({ theme }) => theme.campaigns_grid};
        }
        .campaign-title {
            color: ${({ theme }) => theme.text};
            font-size: 18px;
            font-weight: bold;
        }
       
        

        // TEAMS (part of CAMPAIGN)

        .campaign-teams-dnd {

            height: 680px;
            overflow: scroll;

            ::-webkit-scrollbar {
                -webkit-appearance: none;
                
            }
            
            ::-webkit-scrollbar:vertical {
                width: 4px;
            }
            
            ::-webkit-scrollbar:horizontal {
                height: 0px;
            }
            
            ::-webkit-scrollbar-thumb {
                border-radius: 8px; 
                background-color: rgba(white, 0.2);
                
            }
        }

        .all-teams-title-textbox{
            display: flex;
            justify-content: space-between;
                
                .all-teams-title {
                    color: ${({ theme }) => theme.teams_title};
                    font-size: 18px;
                    font-weight: bold;
                    padding-left: 16px;
                }

                .teams-dnd-search-box{
                    margin-bottom: unset !important;
                }

                .search-input-teams-dnd{
                    background-color: ${({ theme }) =>
                      theme.campaigns_grid} !important;
                }
        }

        .selected-teams-title {
            color: ${({ theme }) => theme.teams_title};
            font-size: 18px;
            font-weight: bold;
        }
    
        .all-teams-hline {
            border-bottom: 1px solid ${({ theme }) => theme.teams_hline};
            margin-top: 5px;
            margin-bottom: 5px;
            margin-left: 16px;
        }
     
     
        .t-avatar {
            position: relative;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border-radius: 50em;
            width: 64px;
            height: 64px;
            font-size: 14.4px;
            padding-top: 25px;
        }
     

        //EVENT (part of CAMPAIGN)
       
        .date-input {
            width: 200px;
        }

       
        .icon {
            display: inline-block;
            color: inherit;
            text-align: center;
            fill: currentColor;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size) {
            width: 1rem;
            height: 1rem;
            font-size: 1rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-xxl {
            width: 2rem;
            height: 2rem;
            font-size: 2rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-3xl {
            width: 3rem;
            height: 3rem;
            font-size: 3rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-4xl {
            width: 4rem;
            height: 4rem;
            font-size: 4rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-5xl {
            width: 5rem;
            height: 5rem;
            font-size: 5rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-6xl {
            width: 6rem;
            height: 6rem;
            font-size: 6rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-7xl {
            width: 7rem;
            height: 7rem;
            font-size: 7rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-8xl {
            width: 8rem;
            height: 8rem;
            font-size: 8rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-9xl {
            width: 9rem;
            height: 9rem;
            font-size: 9rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-xl {
            width: 1.5rem;
            height: 1.5rem;
            font-size: 1.5rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-lg {
            width: 1.25rem;
            height: 1.25rem;
            font-size: 1.25rem;
          }
          
          .icon:not(.icon-c-s):not(.icon-custom-size).icon-sm {
            width: 0.875rem;
            height: 0.875rem;
            font-size: 0.875rem;
          }

        .name {
            vertical-align: middle;
        }
        .points {
            vertical-align: middle;
        }
        .registered {
            vertical-align: middle;
        }
        .show_details {
            vertical-align: middle;
        } 
    
        .team-players-select {
            margin-bottom: 20px;
        }
        
        .col-6 {
            padding-right: 6px;
            padding-left: 6px; 
        }
 

        // TEAM FORMS (ADD, UPDATE)

        .add-team {
            border: unset;
        }
    
        .team-form {
            padding: unset;
            padding-top: 24px;
        }
    
        .form-title {
            padding: unset;
            padding-bottom: 8px;
            margin-bottom: 24px;
            margin-left: 0px;
            margin-right: 0px;
    
            border-bottom: 1px solid;
            background-color: #131313;
            border-color: #d8dbe0;   
            font-size: 18px;
        }
    
        .form-header {
            margin-bottom: 12px;
        }    
    
        .form-header-title {
            margin-top: 14px;
            font-size: 24px;
        }
    
        .update-team-form-header-title {
            margin-top: 14px;
            margin-left: 16px;
        }
   
 
    
        .danger {
            color: #FF6A67;
            background-color: transparent;
            border: 1px solid #FF6A67; 
            margin-top: 8px;
            margin-bottom: 0px;
        }
    
        .success {
            color: #49bd95;
            background-color: transparent;
            border: 1px solid #49bd95; 
            margin-top: 8px;
            margin-bottom: 0px;
        }
    
        
         
      
        .form-buttons {
            
            padding-right: 0px;
            margin-right: 0px;
            display: flex;
          align-items: center;
  gap: 5px;
        }







        /* USERS */

        // message
        .messages {
            margin-left: 20px; 
        }
    
        .first-class {
            margin-left: 10px;
        }
    
        // counter
        .counter {
            vertical-align: middle;
            margin-top: 4px;
            color: #4f5d73;
            font-style: italic;
            width: 1200px;
        } 
        
        //fields
        .name {
            vertical-align: middle;
        }
        .email {
            vertical-align: middle;
        }
        .organization {
            vertical-align: middle;
        }
        .score {
            text-align: left;
            vertical-align: middle;
        }
        .role {
            vertical-align: middle;
        }
        .show_details {
            vertical-align: middle;
        }         
 

        // USERS FORMS (ADD, UPDATE)
        .add-user {
            border: unset;
        }
        .user-form {
            padding: unset;
            padding-top: 24px;
        }

           
       .update-user {
            border: unset;
        }
    
        .update-form-title {
            padding: unset;
            padding-bottom: 8px;
            margin-bottom: 24px;
            margin-left: 0px;
            margin-right: 0px;
    
            border-bottom: 1px solid;
            
            border-color: #d8dbe0;   
            font-size: 18px;
        }
     
        .update-user-title {
            margin-bottom: 0px;
            font-size: 24px;
        }
     
        /* SETTINGS */
        .settings-card {
            border: unset;
        }
    
        .settings-form {
            padding: unset;
            padding-top: 24px;
        }  

        .textarea-input {
            resize: none;
        }

 
        .brief-summary-input {
            resize: none;
        }
    
        .edit-button-row {
            padding-bottom: 20px;
        }




        // ADD CAMPAIGN MODAL
        .campaign-card {
            border: unset;
        }
    
        .campaign-form {
            padding: unset;
            padding-top: 24px;
        }    
 
      

        /* LICENSE */
        .license-wrapper{
            width: 100%;
            height: 100%;
            padding-bottom: 16px;
        }
    
        .license-container {
            margin-top: 16px;
            width: 100%;
            border-left-width: 12px;
            border-left-style: solid;
            border-image: 
              linear-gradient(
                to bottom, 
                rgb(40, 121, 107), 
                rgba(1, 252, 173, 0.65)
              ) 1 100%;
            box-shadow: 0 -0.5px 3px 0 rgba(0, 0, 0, 0.35), 0 1px 2px 0 rgba(0, 0, 0, 0.12);
        }
 
        .text-bold {
            font-weight: bold;
            padding-right: 5px;
        } 
        
 
        .license-buttons {
            padding-right: 16px;
            margin-top: 16px;
            .form-control:focus {
                color: unset;
                background-color: unset;
                border-color: unset;
                outline: 0;
                box-shadow: unset;
            }
    
        }



        /* Tenants */
        .license-valid {
            vertical-align: middle;
        }
        .license-module {
            vertical-align: middle;
        }



        /* NETWORK MAP (Part of attacker) */

        .loaderCimpire{
            width :168px;
            height:168px
        }
        
        .NetworkMap{
            height: 100%;
            width: 100%;
            font-family: 'Roboto', sans-serif !important;
            padding: 0px 16px;
            border: 1px solid ${({ theme }) => theme.network_map_border};
            background-color: ${({ theme }) => theme.network_map_background};
            border-radius: 1em;
            margin: 20px;
        }
        
        .dropHere{
            opacity: 0.3;
            font-size: 12px;
        }
        
        .network-grid{
            display: flex;
            height: calc(100% - 100px);
            margin: 20px;
        }
        
        
     
        
        .sidebarBody{
        padding: 8px;
        }
      
        
        .nav.nav-tabs{
            margin: 0 auto;
        }
        
        .classesListPaints{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
        }
        .newworkmapPaints{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 4px;
            padding-top: 10px;
        } 
        
    
        
        
        .paintsSidebar{
            margin-top: 5px;
        }
        
        .nav-link{
            cursor: pointer; 
            user-select: none;
        }
      
        
        .dnd-field{
            min-height: 200px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: lightgray;
            font-weight: bold;
        }
        .dnd-wrapper{
            padding: 0px 5px;
            max-width: 1332px;
        }
        .specificClass{
            border: 1px solid ${({ theme }) => theme.network_map_border};
            border-radius: 4px;
            overflow-y:hidden;
            display: flex;
            flex-direction: row;
            align-items: center;
            color:grey !important;
            height: 70px;
            position: relative;
            margin: 5px 5px;
        }
        .dropInstance{
            height: 100px;
        }
        
          .specificIconInstance{
              width: 30px;
              height: 30px;
          }
          .networkWrapper{
             
              height: 70px;
          }
          .classTitle{
              font-weight: 300;
              margin: 0px 2px;
          }
          .subnetNameDnd{
              margin-right: 2px;
              margin-left: 6px;
              color: ${({ theme }) => theme.network_map_text};
          }
        
        .networkWrapper.C{
            width: 33.333333%;
        }
        .networkWrapper.B{
            width: 50%;
        }
        .networkWrapper.A{
            width: 100%;
        }
        .objectLine{
            box-sizing: border-box;
            font-size: 1rem;
            text-align: center;
        }
        .networkTitle{
            display: flex;
            align-items: center;
            font-weight: bold;
            color: grey;
            font-size: 12px;
        }
        .titleWrapper{
            width: fit-content;
            cursor: pointer!important;
        }
        .opacityHover:hover{
            cursor: pointer;
            opacity: 0.65;
            user-select: none;
        }
        .bold{
            font-weight: bold;
        }
      
        .objectOpacitySize{
            font-size: 10px;
        }
        .selectMachine{
            text-align: start;
            font-size: 12px;
            color: grey;
            padding-bottom: 8px;
            user-select: none;
        }
        /*
        .lampSubent{
            margin: 0px 6px;
        }
        */
        /*glass input*/
        .search-box {
            border: 1px solid rgba($color: #000000, $alpha: 0.2);
            border-radius: unset;
            height: 28px;
        }
        
        .input-group.search-box{
            margin-bottom: 5px;
        }
       
        .centerButtonNMap{
            justify-content: center;
            align-items: center;
            display: flex;
        }
        
        /*end glass input*/
        @media screen and (max-width: 1600px) {
           .dnd-wrapper{
            max-width: 1055px;
           }
          
          }
        
        .saveBtnModal{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 30px;
            color: #fff;
            width: 90px;
            font-size: 12px;
            height: 24px;
            background: #49bd95;
            border-radius: 4px;
        }
        .cancelBtnModal{
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 30px;
            color: #7F7F7F;
            width: 90px;
            font-size: 12px;
            font-weight: bold;
            height: 24px;
            background: #EBEDEF;
            border-radius: 4px;
        }
        
        .createNetworkBtn{
            padding: 6px 30px;
            color: #fff;
            font-size: 12px;
            background: #152433;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 180px;
            height: 32px;
            text-align: center;
            
        }
        .createNetworkWrapperDiv{
            display: flex;
            justify-content: center;
        }
        .marginAuto{
            margin: 0 auto !important;
        }
        .svgAtSubnetFromGrid{
            width: 40px;
            height: 40px;
        }
        .redColor{
            color:red;
        }
        
         

        .labelSpan.editModal{
            margin-right: 15px;
        }
        .bodyEditSubnetModal{
            text-align: start;
            padding: 8px 0px;
        }
        .subnetCidrModalInput{
            width: inherit;
            border: none;
            outline: unset;
            font-size: 16px;  
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0; 
        }
      
        

        /*instance modal*/
        
        .headerModalInstanceDetails{
        display: flex;
        padding: 16px 0px;
        margin: 16px;
        }
        .modalBodyInstanceDetails{
            padding: 20px 0px;
        }
        .modalHeaderInstanceVerification{
            justify-content: center;
        }
        
        
        .checkboxModal{
            margin: unset!important;
            position: static;
        }
        
         
       
        
        .playeysForms, .adminTextboxes{
            display: flex;
        }
        .adminTextboxes{
            margin-top: 6px;
            display: flex;
        }
        
        .adminTextboxes>span {
            flex: 1;
        }
        
      
        
        .deleteSwitchTrash{
            right: 16px;
            position: absolute;
            display: flex;
            justify-content: space-between;
        }
        .deleteSpan{
            margin: 0px 8px;
        }
        .instancePaintModal{
            width: 30px;
            height: 30px;
            margin-right: 10px;
        }
        .playerForm{
            flex: 1;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 8px;
            margin-top: 8px;
            margin-right: 10px;
        }
        .marginRightUnset{
            margin-right: unset;
        }
        .marginLeftUnset{
            padding-left: unset!important;
        }
        .collapseForms{
            text-align: start;
            margin-bottom:16px;
        }
        
        #modalInstaceDetails, #modalSubentDetails{
            margin-top: 200px;
        }
        .alignItems{
            display: flex;
            align-items: center;
        }
         

        .instanceModalTitleEdit{
            
            text-align: start;
        }
        
        .marginLeft10{
            margin-left: 10px;
        }
        .titleInstanceModal{
            
        }
        .sliderWrapperModal{
            margin: 0 auto;
            height: 35px;
        }
        .sizeDetailsInstanceModal{
            font-size: 12px;
            text-align: start;
        }
        .editTextboxBtn{
            color: #7F7F7F;
        }


        .startup-script-title {
            padding-top: 8px !important;
            padding-bottom: 8px !important;
        }

        .startup-script-description {
            padding-top: 8px !important;
            padding-bottom: 8px !important; 
        }

       

        .startup-script-textarea:focus {
            font-family: 'Courier New', monospace !important;
            color: ${({ theme }) => theme.startup_script_text_color}; 
            border: 1px solid ${({ theme }) =>
              theme.startup_script_border_color};
            line-height: 16px;
            font-size: 14px;
        }
        
        

        /*subnet details modal*/
        .leftHeaderModalSubnet{
            flex: 1;
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 12px;
            border-right: 2px solid rgba(0,0,0,.1);
            border-image: linear-gradient(190deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.1),rgba(0,0,0,.2),hsla(0,0%,100%,.1),hsla(0,0%,100%,0)) 1 100%;
        margin-right: 16px;
        
        }
        .rightLastSectionSubnetModal{
            flex: 1;
            display: flex;
            align-items: center;
        }
        .rightHeaderAndDivider{
            flex: 1.5;
        }
        .connectedIpDDLSubnetModal{
            text-align: start;
        }
        
        .rightHeader{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 12px;
            text-align: start;
            padding-left: 8px;
            
        }
        .cidrWrapper{
            display: flex;
            align-items: center;
        }
        .radioCircleModal{
        padding: 0px 9px 0px 0px !important;
        }
        .inputAndEditSubnetModal{
        display: flex;
        align-items: center;
        }
        .shotDownAndSwichModal{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .deleteAndTrashSubent{
            position: absolute;
            right: 16px;
            display: flex;
            align-items: center;
            
        }
        .errMsg{
            color:red;
            font-size: 12px;
            margin-top: -10px;
            margin-bottom: -5px;
            height: 20px;
        }
        
        
        .margin4{
            margin: 4px;
        }
        .subnetModalInput{
        border: none;
        outline: unset;
        background-color: ${({ theme }) => theme.network_map_input};
        color: ${({ theme }) => theme.network_map_text};
        }
        .cidrSpan{margin-right: 4px;}
        .marginRight80{
            margin-right: 80px;
        }
        .marginRight20{
        margin-right: 20px !important;
        }
            
            .dividerModal20{
            background-color: rgba(151,151,151,0.65);
            width:1px;
            height: 120px;
            }
            .disabledOkBtnModal{
                opacity: 0.4;
            }
            .nameAndLogo{
                display: flex;
                flex-direction:column ;
                align-items: center;
                margin-top: 5px;
            }
            .instanceTitleInsideSubnet{
                font-size: 8px;
                margin:2px 4px;
                height:12px;
                text-align:center;
                white-space: nowrap; 
                width: 90%; 
                overflow: hidden;
                text-overflow: ellipsis; 
            }
            .spanBeforeInput{
        
                /*padding: 4px;*/
                display: flex;
                align-items: center;
                min-height: 32px;
                box-sizing: border-box;
            }
           
            .titleRightPanelInstance{
                width: 80px;
                text-align: start;
            }
            .sliderNumberModal{
                width: 30px;
            }
            .storageInput{
                max-width: 30px !important;
                margin-right: -5px;
            }
            .plusMinusButton{
                border-radius: 50%;
                color: #fff;
                background-color: #49bd95;
                cursor: pointer;
                width: 20px;
                height: 20px;
                margin: 0px 5px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .plusIcon{
                border-radius: 50%;
                width: 18px;
                height: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #595959;
                margin: 0 auto;
                margin-top: 16px;
                margin-bottom: 16px;
                cursor: pointer;
                border: 1px solid #595959;
                user-select: none;
            }
            .specificDDL{
                display: inline-block;
                min-width: 145px;
            }
        
            .ruleLine{
                border: dashed 1px grey;
                box-sizing: border-box;
                margin: 11px 25px;
                margin-bottom: 5px;
                padding: 8px;
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                gap: 5px;
                position: relative;
                border-radius: 2px;
            }
            .minusEditSubnetModal{
                position: absolute;
                top: 0px;
                left: -28px;
                font-size: 20px;
                padding-bottom: 2px;
            }
            .ddlToggleBorder:focus{
        box-shadow: none;
            }
            .greyBorder{
                border: 1px solid grey;
                border-radius: 4px;
            }
            .specificTitle{
                font-weight: bold;
                width: 125px;
            }
        
            .titlesLine{
            padding: 8px;
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            grid-gap: 5px;
            gap: 5px;
            margin: 11px 25px;
            }
            .ddlRuleSubnetModal{
                max-width: 125px;
            }
            .specificTitleTextboxModal{
                min-width: 145px;
            }
            .minusSymbol{
                margin: 5px;
            }
            .srcPortModal{
                display: flex;
            }
        
            .rulesList{
            padding: 2px;
            max-height: 270px;
            
            }
            
            .rulesList::-webkit-scrollbar {
            width: 3px;
            }
            
            /* Track */
            .rulesList::-webkit-scrollbar-track {
            background: #f1f1f1; 
            }
            
            /* Handle */
            .rulesList::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 15px; 
            }
            
            /* Handle on hover */
            .rulesList::-webkit-scrollbar-thumb:hover {
            background: #555; 
            }
            .disableRuleModal{
                opacity: 0.5;
                cursor:not-allowed ;
            }
            .dropdown-toggle.btn.disabled{
            cursor:not-allowed ;
            }
            
        /*end subnet details modal*/
        .disableClick{
            cursor: not-allowed;
        }
        .imageAmiWrapper{
            margin-top: 30px;
        }
        /*Create Network Modal:*/
        
        .createNetworkModalTitle{
            margin: 0 auto;
        }
        
        .createNetworkModalTitleSpan{
            padding: 3px;
        }
        .labelCreateNetworkModal{
            width: 50px;
            display: inline-block;
            text-align: start;
            margin-right: 20px;
        }
        .cidrTitleCreateNetworkModal{
            font-weight: bold;
            margin-right: 4px;
        }
        /*End Create Network Modal:*/
        /*Update Network Modal:*/
        .headerModalUpdateNetwork{
            position: relative;
        }
        .deleteAndTrashUpdateModal{
            position: absolute;
            top: 24px;
            right: 0px;
            font-weight: 300;
            display: flex;
            align-items: center;
        }
        .updateNetworkModalFooter{
            display: flex;
            justify-content: center;
        }
        .headerModalUpdateNetwork{
            display: block;
            padding: 24px;
        }
        .cidrWrapperUpdateNetworkModal{
            display: flex;
            align-items: center;
            margin-bottom: 0;
            
        }
        .marginRightUnset{margin-right: unset;}
        .updateCreateNetworkModalFooter{
            justify-content: center;
        }
        .updateNetworkBtnModal{
            border: dashed 1px grey;
            width: 170px;
            height: 24px;
            border-radius: 4px;
            margin: 0 auto;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #fff;
            color: grey;
        }
        
        
        .updateNetworkBtnWrapper{
            padding-bottom:40px;
            position: relative;
        }

        .marginRight5{
            margin-right: -5px;
        }
        .connectedHirarchy{
            margin-left: 8px;
            width: 17px;
            opacity: 1.0;

            opacity: 1;
            display: block;
            background: url(${({ theme }) => theme.network_icon}) no-repeat;
            width: 16px;
            height: 16px;
            border-width: thin;
            outline: unset;


        }
        .marginRight7{
            margin: 0px 7px;
        }
        /*
        verification networkk modal
        */
        
        /*
        END verification networkk modal
        */
        
        
        /*END Update Network Modal:*/
        @media screen and (max-width: 1800px) {
            #modalInstaceDetails,#modalSubentDetails{
                margin-top: 90px !important;
            }    
            .modal-dialog.modal-xl{
               /* max-width: 60%;*/
            }   
        }
        .marginRight10{
            margin-right: 10px;
        }
        .alignCenterFlex{
            display: flex;
            align-items: center;
        }
        .borderRdius4{
            border-radius: 4px;
            border: 1px grey solid;
        }
        .borderRdius4:focus{
            border-radius: 4px;
            outline: grey;
        }
        /*drop down css*/
        .ctypeInstanceModal{
            min-width: 150px;
            outline: unset;
        }
        .ctypeInstanceModal{
           text-align: start ;
        }
        .connectSubnetModal{
            max-width: 265px;
            text-align: start !important;
        }
        
        /*end drop down css */
        
        


        .network-button{
            width: 120px;
            height: 28px;
            background-color: ${({ theme }) => theme.button};
            border:  1px solid ${({ theme }) => theme.button_border};
            color: ${({ theme }) => theme.button_text};
            border-radius: 0.6em;
            font-weight: 500;
            float: right;
            font-size: 11px;
            padding-top: 6px;
        }
    
        .network-button:active{
            background-color: ${({ theme }) => theme.button};   
        }
        .network-button:hover{ 
            border-color: ${({ theme }) => theme.button_border_hover};
            color: ${({ theme }) => theme.button_text_hover};
        }
        .network-button:not(:disabled):not(.disabled):active:focus, .show > .network-button.dropdown-toggle:focus {
            box-shadow: unset;
        }


        







        /* Main Dashboard */

        .map-container {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            height: 65vh;
            margin: 16px;

            :focus {
                outline: unset !important;
            }
        }
        
        .mapboxgl-canvas:focus {
            outline: unset !important;
        }
 
        #marker {
            background-image: url(${logo_mask});
            background-size: cover;
            width: 20px;
            height: 30px; 
            cursor: pointer;
            position: absolute;
            top: 0;
           
        }
        
        .mapboxgl-popup {
            position: absolute;
            top: 0;
            width: 200px; 
        }

        .mapboxgl-popup-content {
            background-color: rgba(0,0,0, 0.7);
            color: rgba(white, 0.5);
            font-size: 10px;
            padding: 10px;
            border-radius: 0.5em;

            .red {
                padding: 5px;
                border-left: 2px solid ${({ theme }) => theme.red_tabs_border};
            }
            .blue {
                padding: 5px;
                border-left: 2px solid ${({ theme }) => theme.blue_tabs_border};
            }
        }

        .mapboxgl-popup-close-button {
            background-color: white;
            color: black;
            border: unset;
        }
        
   
 
        .event-dashboard-hints-modal-description{
            p{
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
 
  
    


        .connect-link {
            cursor: pointer;
        }





        /* ATTACK SCENARIO */


        .attackDDLModal{
            margin-bottom: 24px;
            margin-top: 16px;
        }

        .attack-scenario-phase-modal-body {
            max-height: 492px;
            .col-md-6 {
                padding-left: 0px;
            }

            .form-group {
                align-items: center;
            }
            label {
                display: inline-block;
                margin-bottom: 0rem;
            }
        }

   
        .disableBtnAttacker{
            cursor: not-allowed;
        }


        .axis{
            padding-top: 200px;
            border-radius: 1em;
            width: 98%; 
            position: relative; 
            margin-left: 10px;  
            display: flex;
            justify-content: space-between;
        }
         
      
        /*header Attacker Verification Phase Modal END*/
        
        @keyframes right-to-left {
            0% {
                transform: translate(750px, 0px);
                opacity: 0;
        
            }
        
            100% {
                transform: translate(0px, 0px);
                opacity: 1;
                visibility: visible;
            }
        }
      
        
       
        #imgPhaseTimeLine{
            width: 100%;
            height: 100%;   
        }
       
       
        .phaseToShowAttacker{
            max-height: 250px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 11;
            position: absolute;
           top: 30px;
        } 

        // READINESS DASHBOARD

        /* Players Dashboard */

        .players_summary_table_container {
            overflow-y: auto;
 
            ::-webkit-scrollbar {
                -webkit-appearance: none;
            }
            
            ::-webkit-scrollbar:vertical {
                width: 4px;
            }
            
            ::-webkit-scrollbar:horizontal {
                height: 0px;
            }
            
            ::-webkit-scrollbar-thumb {
                border-radius: 8px;
                 background-color: rgba(white, 0.2);
            }

            .players_summary_table {
                 
                margin-left: 40px;
                margin-top: 60px;
                margin-bottom: 0px;
        
                thead {
                    width: calc( 600px - 1em )/* scrollbar is average 1em/16px width, remove it from thead width */
            
                }
                tbody {
                    display:block;
                    height:410px; 
                    overflow:auto;
                }
                thead, tbody tr {
                    display:table;
                    width:100%;
                    table-layout:fixed;/* even columns width , fix width of table too*/
                }
        
                .username {
                    cursor: pointer;
                    width:200px; 
                    white-space: nowrap;
                    background-color: ${({ theme }) =>
                      theme.players_dashboard_username_background};
                    border-right: 3px solid ${({ theme }) => theme.background};
                    border-bottom: 3px solid ${({ theme }) => theme.background};
                }
         
        
                border-collapse: collapse;
        
                th {
                  padding-top: 0px;
                  padding-left: 0px;
                }
                td {
                    width: 44px; // must be same as th.rotate width
                  text-align: center; 
                  border-top: 1px solid ${({ theme }) => theme.table_row};
                  border-right: 1px solid ${({ theme }) => theme.table_row};
                  border-bottom: 3px solid ${({ theme }) => theme.table_row};
                }
        
                th.rotate {
                  height: 140px;
                  width: 44px; // must be same as td width
                  white-space: nowrap;
                  // Firefox needs the extra DIV for some reason, otherwise the text disappears if you rotate 
                  > div {
                    transform: 
                      // Magic Numbers
                      translate(32px, 51px)
                      // 45 is really 360-45
                      rotate(315deg);
                      //rotate(285deg);
                    width: 20px;
                  }
                  > div > span {
                    border-bottom: 1px solid ${({ theme }) => theme.table_row};
                    padding: 5px 10px;
                    width: 100%;
                  }
                }
                th.row-header { 
                  border-bottom: 1px solid ${({ theme }) => theme.table_row};
                }      
        
                .score-tooltip{
                    position:relative;
                } 
              
            }            

        } 
 
          .legend {
            display: none;
        }

 
        .marker {
            white-space: nowrap;
            font-size: 8px;
            margin-top: 6px;
        }
 
        .chat-content {

            html, body, #root {
                width: 100%;
                height: 100%;
                background-color: rgb(255, 57, 63);
            }

  
            iframe {
                border-width: 2px !important;
                border-style: solid !important;
                 border-color: rgba(114, 137, 218, 0.5) !important;
                 border-radius: 1.0em;
            }  


        }
     
        .css-1fm2mhz-Button-Button-Button-focusable-Join {
            background-color: red;
        }
     


        /* LOGS */
        .message {
            overflow: hidden;
            white-space: nowrap;
        }
   
       
        .dbrd-readiness-badge-placeholder{
            height: 141px;
            & img{
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
        }

        svg g g.multi-lines-graph path.domain,
        svg g g.multi-lines-graph line {
            display: none;
        
        }  

        svg g g.multi-lines-graph text{
            fill:rgba(255, 255, 255, 0.25);
        } 

        .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
            background-color: rgba(255, 255, 255, 0.15);
        }
        
    }
`;
