import "react-app-polyfill/stable";
import "core-js";
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  // <StrictMode>
  <App />
  // </StrictMode>
);

setTimeout(() => {
  try {
    let system = JSON.parse(localStorage.getItem("system"));
    const titleRoot = createRoot(document.getElementById("title"));
    titleRoot.render(system.platform_name || "");
  } catch {
    const titleRoot = createRoot(document.getElementById("title"));
    titleRoot.render("");
  }
}, 3000);

serviceWorker.unregister();
