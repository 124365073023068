import React, { useEffect } from "react";
import { HashRouter, Switch } from "react-router-dom";
import {
  ThemeProvider,
  //useTheme
} from "styled-components";
import {
  ProtectedRoute,
  UnauthenticatedRoute,
  isAuthenticated,
} from "routes/protected-route";
import { GlobalStyles } from "./styles/global";
//import "./scss/style.scss";
import { observer } from "mobx-react";
import { appConfigurationStore } from "common/stores/app-configuration-store";
import { setAppTheme } from "common/actions/app-configuration-actions";

const loading = (
  <div className=" text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() =>
  import("./containers/layouts/layout-manager")
);

// Pages
const Auth = React.lazy(() => import("pages/unauthorized/unauth-main"));

const App = observer(() => {
  const { appTheme } = appConfigurationStore;
  //const theme = useTheme();
  //const [loading, setLoading] = useState(false);

  useEffect(() => {
    init();
    // SET DEFAULT THEME
    //this.setState({ theme: blackMamba });
  }, []);

  const init = () => {
    const settings = JSON.parse(localStorage.getItem("settings"));
    let themeName = settings?.theme;
    if (!themeName) {
      const isPlayer = JSON.parse(localStorage.getItem("login"))?.player;
      themeName = isPlayer ? "blog_light_theme" : "blackMamba";
    }
    setAppTheme(themeName);
  };

  return (
    <ThemeProvider theme={appTheme}>
      <GlobalStyles />
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <UnauthenticatedRoute
              exact
              path="/login"
              name="Login Page"
              component={Auth}
              render={(props) => <Auth {...props} />}
            />
            <UnauthenticatedRoute
              exact
              path="/auth"
              component={Auth}
              render={(props) => <Auth {...props} />}
            />
            <UnauthenticatedRoute
              path="/auth/:mode"
              component={Auth}
              render={(props) => <Auth {...props} />}
            />
            <UnauthenticatedRoute
              path="/auth/:mode/:username"
              component={Auth}
              render={(props) => <Auth {...props} />}
            />
            <UnauthenticatedRoute
              path="/register/event/:event_id"
              component={Auth}
              render={(props) => <Auth {...props} />}
            />
            <UnauthenticatedRoute
              path="/confirm/:username"
              component={Auth}
              render={(props) => <Auth {...props} />}
            />
            <UnauthenticatedRoute
              path="/forgot-password"
              component={Auth}
              render={(props) => <Auth {...props} />}
            />

            <ProtectedRoute
              path="/"
              component={TheLayout}
              isAuthenticated={isAuthenticated()}
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    </ThemeProvider>
  );
});

export default App;
