import React, { FC } from "react";
import {
  Route,
  Redirect,
  RouteProps,
  RouteComponentProps,
} from "react-router-dom";
//import { LocationState, LocationDescriptor, createLocation } from "history";

export interface CustomLocationState {
  from?: { pathname: string };
}
interface AuthProps {
  isAuthenticated?: boolean;
}
interface ProtectedRouteProps extends AuthProps, RouteProps {
  component: React.ComponentType<RouteComponentProps<any>>;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  component: Component,
  //isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const isAuth = isAuthenticated();
        //console.log("ProtectedRoute>>>", isAuth, props.location);
        return isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/auth/login",
              state: { from: props.location } as CustomLocationState,
            }}
          />
        );
      }}
    />
  );
};

export const UnauthenticatedRoute: FC<ProtectedRouteProps> = ({
  component: Component,
  //isAuthenticated,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const isAuth = isAuthenticated();

        return !isAuth ? <Component {...props} /> : <Redirect to="/" />;
      }}
    />
  );
};

export const isAuthenticated = () => {
  try {
    const login_data = JSON.parse(localStorage.getItem("login") || "{}");

    if (login_data.token) {
      const currentDate = new Date();
      const expDate = login_data.expiration;
      if (expDate && expDate < currentDate.getTime()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  } catch {
    return false;
  }
};
